.footer-bottom {
  ul.menu {
    li.leaf {
      #INDmenu-btn {
        position: static;
        float: none;
        margin: 0;
        color: gray;
      }
      #INDbtnWrap {
        button:hover {
          color: black;
        }
      }
      #INDbtnTooltip {
        top: 55px !important;
      }
    }
  }
}

@mixin highlightLinks {
  color: Red;
  border-bottom: 2px solid red;
  text-decoration: none;
}

.INDhighlightLinks {
  .icon-heart {
    border-bottom: 2px solid red;
  }
  .gnav-drop-down-v1 {
    .link {
      span {
        @include highlightLinks;
      }
      img {
        border-bottom: 2px solid red;
      }
    }
  }
  .page-spp {
    ul.product-grid {
      .product {
        a {
          border-bottom: none !important;
        }
        h3 {
          color: Red !important;
          border-bottom: 2px solid red;
          text-decoration: none;
        }
      }
    }
  }
  .spp_reviews {
    #summary_BVRR_ratings {
      .filter_BVRR {
        a {
          border-bottom: none !important;
          span {
            border-bottom: 2px solid red;
          }
        }
      }
    }
  }
  input[type='submit'] {
    border-bottom: 2px solid red;
  }
}

.INDhighlightLinks,
.INDwhiteblack,
.INDblackwhite {
  .account-header-panel {
    ul.shortcuts {
      .chat {
        a {
          display: none;
        }
      }
    }
  }
}

html:not(#INDdummy) {
  body:not(#INDdummy).INDblackcursor {
    .offer-promo-v1 {
      cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
    }
    .single-video-v1 {
      .image-placeholder.has-video {
        cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
      }
    }
    .mm_3col_1tout_v1 {
      .tout-image-v1.tout-image {
        cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
      }
    }
    .gnav-drop-down-v1 {
      .dd-icon .link {
        cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
      }
    }
    .Ratings_BVRR {
      .ratingsummary_BVRR {
        .review_BVRR {
          a {
            span#BVRR_WriteAReview {
              cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
            }
          }
        }
        .filter_BVRR {
          a {
            span {
              cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
            }
          }
        }
      }
    }
    .locations_map_panel {
      #map {
        .gm-style div {
          cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
        }
      }
    }
    .vertical-carousel-navigation-v1 {
      .item {
        cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
      }
    }
    .module-spp-detail {
      .icon-large {
        cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
      }
    }
    #form--profile--field--UPLOADED_FILE {
      cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
    }
  }
  body:not(#INDdummy).INDwhitecursor {
    .offer-promo-v1 {
      cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
    }
    .single-video-v1 {
      .image-placeholder.has-video {
        cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
      }
    }
    .mm_3col_1tout_v1 {
      .tout-image-v1.tout-image {
        cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
      }
    }
    .gnav-drop-down-v1 {
      .dd-icon .link {
        cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
      }
    }
    .Ratings_BVRR {
      .ratingsummary_BVRR {
        .review_BVRR {
          a {
            span#BVRR_WriteAReview {
              cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
            }
          }
        }
        .filter_BVRR {
          a {
            span {
              cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
            }
          }
        }
      }
    }
    .locations_map_panel {
      #map {
        .gm-style div {
          cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
        }
      }
    }
    .vertical-carousel-navigation-v1 {
      .item {
        cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
      }
    }
    .module-spp-detail {
      .icon-large {
        cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
      }
    }
    #form--profile--field--UPLOADED_FILE {
      cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
    }
  }
}

.INDfontsize11,
.INDfontsize12,
.INDfontsize13,
.INDfontsize14,
.INDfontsize15 {
  #navigation {
    .outer-wrap {
      max-width: 100% !important;
      .nav-menu {
        width: 70% !important;
      }
      .navigation-right {
        width: 30% !important;
      }
    }
  }
  #node-47496 {
    width: 100% !important;
  }
}

.INDmagnifier {
  #navigation {
    .outer-wrap {
      max-width: 100% !important;
      .nav-menu {
        width: 70% !important;
      }
      .navigation-right {
        width: 30% !important;
      }
    }
  }
  #node-47496 {
    width: 100% !important;
  }
}

.INDwhiteblack {
  .cs-page-section-row-v1 {
    .wrapper-link {
      span {
        background: transparent !important;
        outline: 1px dashed MidnightBlue;
      }
      span:hover {
        background-color: lemonchiffon !important;
        opacity: 0.9;
      }
    }
  }
  .vertical-carousel-navigation-v1 {
    .full-width-2-row-v1 {
      .outer-wrap {
        .top {
          h3,
          span,
          p {
            color: black !important;
          }
        }
        .cta {
          a,
          a:hover {
            color: black !important;
          }
        }
      }
    }
  }
  .cs-page-section-row-v1:last-child {
    .wrapper-link {
      span:last-child {
        border-bottom: 1px dashed MidnightBlue;
      }
    }
  }
  #contact-form-wrap {
    .button_container {
      input[type='submit'] {
        outline: 1px dashed MidnightBlue;
        margin: 0 0 2px 2px;
      }
      input[type='submit']:hover {
        background-color: lemonchiffon !important;
      }
    }
  }
  .email_check {
    .row {
      input[type='submit'] {
        outline: 1px dashed MidnightBlue;
        margin-right: 2px;
      }
      input[type='submit']:hover {
        background-color: lemonchiffon !important;
      }
    }
  }
  .detailsfooter_BVRR {
    .buttonsid_BVRR {
      input[type='submit'] {
        outline: 1px dashed MidnightBlue;
        margin-left: 10px;
      }
      input[type='submit']:hover {
        background-color: lemonchiffon !important;
      }
    }
  }
  #return-user {
    #new-account-link {
      border: none !important;
    }
    .signin_submit {
      input[type='submit'] {
        outline: 1px dashed MidnightBlue;
      }
      input[type='submit']:hover {
        background-color: lemonchiffon !important;
      }
    }
  }
  .popup-offer-v1 {
    .form-submit-wrap {
      input {
        outline: 1px dashed MidnightBlue;
      }
    }
  }
  .login-email {
    input.button {
      outline: 1px dashed MidnightBlue;
      margin-right: 2px;
    }
  }
}

.INDblackwhite {
  .cs-page-section-row-v1 {
    .wrapper-link {
      span {
        background: transparent !important;
        outline: 1px dashed Gold;
      }
      span:hover {
        background-color: lemonchiffon !important;
        opacity: 0.9;
      }
    }
  }
  .cs-page-section-row-v1:last-child {
    .wrapper-link {
      span:last-child {
        border-bottom: 1px dashed Gold;
      }
    }
  }
  .vertical-carousel-navigation-v1 {
    .full-width-2-row-v1 {
      .outer-wrap {
        .top {
          h3,
          span,
          p {
            color: white !important;
          }
          .cta {
            div,
            a,
            a:hover {
              color: black !important;
            }
          }
        }
      }
    }
    .dots {
      .item {
        background-color: white !important;
      }
    }
    .product-content-grid-v1.columns-5 {
      background: black !important;
    }
  }
  #contact-form-wrap {
    .button_container {
      input[type='submit'] {
        outline: 1px dashed Gold;
        margin: 0 0 2px 2px;
      }
      input[type='submit']:hover {
        background-color: lemonchiffon !important;
        color: black !important;
      }
    }
  }
  .email_check {
    .row {
      input[type='submit'] {
        outline: 1px dashed Gold;
        margin-right: 2px;
      }
      input[type='submit']:hover {
        background-color: lemonchiffon !important;
        color: black !important;
      }
    }
  }
  .detailsfooter_BVRR {
    .buttonsid_BVRR {
      input[type='submit'] {
        outline: 1px dashed Gold;
        margin-left: 10px;
      }
      input[type='submit']:hover {
        background-color: lemonchiffon !important;
        color: black !important;
      }
    }
  }
  #return-user {
    #new-account-link {
      border: none !important;
    }
    .signin_submit {
      input[type='submit'] {
        outline: 1px dashed Gold;
      }
      input[type='submit']:hover {
        background-color: lemonchiffon !important;
        color: black !important;
      }
    }
  }
  #top {
    #header {
      background: black !important;
    }
  }
  .gnav-offer-header {
    p {
      color: white !important;
    }
  }
  .hotlists-nav-header,
  .header-nav-formatter-v1,
  .mpp-filter,
  .menu-item-block,
  .store-locator-header,
  .module-how-use,
  .Ratings_BVRR {
    background: black !important;
  }
  .popup-offer-v1 {
    .form-submit-wrap {
      input {
        outline: 1px dashed Gold;
      }
    }
  }
  .login-email {
    input.button {
      outline: 1px dashed Gold;
      margin-right: 2px;
    }
  }
}

.INDreadable {
  div,
  ul,
  li,
  a,
  p,
  span,
  table,
  tr,
  th,
  td,
  input,
  form,
  h1,
  h2,
  .module-benefits .heading-container,
  .module-benefits .heading-container *,
  h3,
  h4,
  h5,
  dl,
  dt,
  dd,
  label,
  small,
  select,
  article {
    font-family: Arial !important;
  }
}

.INDfontsize11,
.INDfontsize12,
.INDfontsize13,
.INDfontsize14,
.INDfontsize15 {
  .mpp-grid {
    #mpp-product-grid {
      li.mpp-box {
        height: auto !important;
      }
    }
  }
  .vertical-carousel-navigation-v1 {
    .module:last-child {
      .full-width-2-row-v1 {
        .top {
          position: relative !important;
          h3 {
            height: 495px;
          }
        }
      }
    }
  }
}

@mixin fontsize($size) {
  font-size: $size;
}

.INDfontsize11 {
  .mpp-grid {
    #quickshop {
      .skin-types-top,
      .prod-desc,
      .sizes,
      .skin-type {
        @include fontsize(14.3px);
      }
    }
  }
}

.INDfontsize12 {
  .mpp-grid {
    #quickshop {
      .skin-types-top,
      .prod-desc,
      .sizes,
      .skin-type {
        @include fontsize(15.6px);
      }
    }
  }
}

.INDfontsize13 {
  .mpp-grid {
    #quickshop {
      .skin-types-top,
      .prod-desc,
      .sizes,
      .skin-type {
        @include fontsize(16.9px);
      }
    }
  }
  .popup-offer-v1 {
    .outer-wrap.signup {
      height: 360px !important;
      width: 475px !important;
    }
  }
}

.INDfontsize14 {
  .mpp-grid {
    #quickshop {
      .skin-types-top,
      .prod-desc,
      .sizes,
      .skin-type {
        @include fontsize(18.2px);
      }
    }
  }
  .popup-offer-v1 {
    .outer-wrap.signup {
      height: 390px !important;
      width: 492px !important;
    }
  }
}

.INDfontsize15 {
  .mpp-grid {
    #quickshop {
      .skin-types-top,
      .prod-desc,
      .sizes,
      .skin-type {
        @include fontsize(19.5px);
      }
    }
  }
  .popup-offer-v1 {
    .outer-wrap.signup {
      height: 390px !important;
      width: 520px !important;
      h2 {
        margin: 20px 0 20px !important;
      }
    }
  }
}

.INDwhiteblack,
.INDblackwhite {
  .vertical-carousel-navigation-v1 {
    .full-width-2-row-v1 {
      .outer-wrap {
        background-color: transparent !important;
      }
      .top {
        background: transparent !important;
        h3,
        p,
        div,
        .subheading {
          background: transparent !important;
        }
        .cta {
          width: 66px;
          background: transparent !important;
          a:hover {
            background-color: LemonChiffon !important;
          }
        }
      }
    }
    .full-width-2-row-v1:last-child {
      .outer-wrap {
        .subheading {
          padding-top: 10px;
        }
      }
    }
    .product-content-grid-v1 {
      .pcg-product-v1 {
        .product-info {
          a {
            margin-right: 2px;
          }
        }
      }
    }
  }
  .supercat-header-v1 {
    .outer-wrap,
    .header-details,
    .subheading,
    h3,
    p {
      background: transparent !important;
      color: black !important;
    }
  }
  .mm_4col_v1 {
    .col-info {
      .button a {
        margin: 0 2px 5px 0;
        width: 98%;
      }
    }
  }
  .mm_3col_1tout_v1 {
    .tout-image-v1:hover {
      background-color: lemonchiffon !important;
    }
    .tout-info {
      background: transparent !important;
      .heading,
      .body,
      .link {
        background: transparent !important;
      }
    }
  }
  .mpp-product-v1 {
    a.btn-favorite {
      display: none !important;
    }
  }
  .locations_map_panel {
    #map {
      div:not(img) {
        background-color: transparent !important;
      }
      div:nth-child(4) {
        div:nth-child(4) {
          div:nth-child(1) {
            div:nth-child(1) {
              div {
                background-color: grey !important;
              }
              div:nth-child(2) {
                div {
                  background-color: grey !important;
                }
              }
              div:nth-child(1) {
                div {
                  background-color: grey !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.mpp-box:hover {
  a {
    color: black;
  }
  a.btn-shopnow {
    color: white !important;
  }
  .product-info {
    span:hover {
      color: #44a07e !important;
    }
  }
}

.INDhighlightHeaders {
  .account-header-panel h3 {
    border-bottom: none !important;
  }
}

.INDblackwhite {
  #viewcart-panel {
    .continue-buttons {
      padding: 2px 2px 2px 0px;
    }
  }
  .checkout.panel {
    #continue-btn {
      padding: 5px 0 0 0;
      input[type='submit'] {
        outline: 1px dashed Gold;
      }
    }
  }
  .vertical-carousel-navigation-v1 {
    .full-width-2-row-v1 {
      .top {
        .cta {
          a.cta {
            color: white !important;
          }
          a.cta.hover {
            color: black !important;
          }
        }
      }
    }
  }
}

.INDwhiteblack {
  #viewcart-panel {
    .continue-buttons {
      padding: 2px 2px 2px 0px;
    }
  }
  .checkout.panel {
    #continue-btn {
      padding: 4px 0 0 0;
      input[type='submit'] {
        outline: 1px dashed black;
      }
    }
  }
  .mpp-grid {
    .mpp-product-hover-view {
      .btn-shopnow {
        color: black !important;
      }
    }
  }
}

.INDreadable {
  .icons {
    i.icon-heart {
      font-family: FontAwesome !important;
    }
  }
}

.INDfontsize11,
.INDfontsize12,
.INDfontsize13,
.INDfontsize14,
.INDfontsize15 {
  #top {
    .top-right {
      &.column {
        .offer-promo-row {
          .headline {
            width: 154px !important;
            line-height: 0.66em !important;
          }
        }
      }
    }
  }
  .column {
    &.right {
      #samples-panel,
      #favorites-panel {
        .product-list {
          .product {
            height: auto !important;
          }
        }
      }
      #favorites-panel {
        .product-list {
          .product {
            .add-to-cart {
              padding: 0px 0px 10px 0px !important;
            }
          }
        }
      }
    }
  }
}

.INDhighlightLinks {
  .mpp-product-v1 {
    .product {
      .btn-favorite {
        display: none;
      }
    }
  }
}

.INDblackcursor {
  input[type='file']::-webkit-file-upload-button {
    cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
  }
  .mm-formatter-v1 {
    .mm-menu {
      .title {
        cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
      }
    }
  }
  .expand-toggle,
  .shade-box {
    cursor: url(/media/export/cms/accessibility/bighandblack.cur), auto !important;
  }
}

.INDwhitecursor {
  input[type='file']::-webkit-file-upload-button {
    cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
  }
  .mm-formatter-v1 {
    .mm-menu {
      .title {
        cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
      }
    }
  }
  .expand-toggle,
  .shade-box {
    cursor: url(/media/export/cms/accessibility/bighandwhite.cur), auto !important;
  }
}

.INDmagnifier {
  #main {
    .responsive-container {
      .checkout__sidebar {
        float: none !important;
        .tab-switcher {
          .tab-content {
            clear: none !important;
          }
        }
      }
    }
  }
}

.INDfontsize13,
.INDfontsize14,
.INDfontsize15 {
  .viewcart-panel {
    .cart-item {
      .qty {
        margin-left: 15px !important;
      }
    }
  }
}

.INDreadable {
  #sticky-bar {
    .icon-search {
      font-family: FontAwesome !important;
    }
  }
}

#header {
  .offer-promo-v1 {
    &.offer-promo-row {
      width: 154px !important;
    }
  }
}

#INDbtnWrap {
  #INDmenu-btn {
    svg {
      height: 35px;
    }
  }
}
