.block-template-shoppable-sku-mix-and-match-item-v1 {
  .mix-and-match--slider {
    // Tweak the height of the mix and match slider to allow for the longer copy and CTA on hover
    .slick-list {
      height: 597px;
    }
    .mix-and-match--item {
      // Tweak the width of the mix and match select button to allow for the longer copy
      .mix-and-match--select--btn {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
}
