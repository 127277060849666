.overlay-container {
  left: 0px;
}

.mpp-sort-by li {
  float: right;
}

.module-spp-detail .shade-info-label {
  float: right;
}

.cl-icon {
  &.acne-breakout {
    background-position: 150px 3px;
  }
  &.acne-breakout.active {
    background-position: 150px -297px;
  }
  &.enlarged-pores {
    background-position: -115px 4px;
  }
  &.enlarged-pores.active {
    background-position: -115px -296px;
  }
  &.lines-wrinkles {
    background-position: -375px 1px;
    padding-#{$ldirection}: 37px;
  }
  &.lines-wrinkles.active {
    background-position: -375px -298px;
  }
  &.daily-care {
    background-position: 150px -67px;
    padding-#{$ldirection}: 38px;
  }
  &.daily-care.active {
    background-position: 150px -367px;
  }
  &.eye-area {
    background-position: -255px -67px;
  }
  &.eye-area.active {
    background-position: -255px -367px;
  }
  &.redness {
    background-position: -375px -67px;
    padding-#{$ldirection}: 37px;
  }
  &.redness.active {
    background-position: -375px -367px;
  }
  &.dark-spots {
    background-position: 150px -137px;
    padding-#{$ldirection}: 38px;
  }
  &.dark-spots.active {
    background-position: 150px -438px;
  }
  &.firming {
    background-position: -253px -137px;
  }
  &.firming.active {
    background-position: -253px -436px;
  }
  &.sensitivity {
    background-position: -375px -136px;
    padding-#{$ldirection}: 37px;
  }
  &.sensitivity.active {
    background-position: -375px -436px;
  }
  &.dryness {
    background-position: 150px -207px;
    padding-#{$ldirection}: 38px;
  }
  &.dryness.active {
    background-position: 150px -507px;
  }
  &.radiance {
    background-position: -115px -207px;
  }
  &.radiance.active {
    background-position: -115px -507px;
  }
  &.sun-protection {
    background-position: -375px -207px;
    padding-#{$ldirection}: 37px;
  }
  &.sun-protection.active {
    background-position: -375px -507px;
  }
}

.flex-direction-nav {
  .flex-prev {
    right: auto;
  }
}

.video-carousel-v1 .flexslider-active {
  position: relative;
}

.flexslider-vertical .flex-direction-nav .flex-prev:hover,
.flexslider-vertical:hover .flex-direction-nav .flex-prev:hover,
.flexslider-vertical .flex-direction-nav .flex-next:hover,
.flexslider-vertical:hover .flex-direction-nav .flex-next,
.flexslider-vertical:hover .flex-direction-nav .flex-next:hover,
.flexslider-vertical .flex-direction-nav .flex-next,
.flexslider-vertical .flex-direction-nav .flex-prev,
.flexslider-vertical:hover .flex-direction-nav a {
  left: 50%;
  right: auto;
}

.full-width-promo-v1 {
  .flexslider {
    &,
    &:hover {
      .flex-direction-nav .flex-prev {
        #{$ldirection}: 25px;
        #{$rdirection}: auto;
      }
      .flex-direction-nav {
        a {
          &.flex-next {
            #{$rdirection}: 25px;
            #{$ldirection}: auto;
          }
        }
      }
    }
  }
}

.product-tout .product .product-info {
  min-height: 100px;
}

.extra {
  .inner {
    .field {
      input#SMS_PROMOTIONS {
        margin-top: 5px;
      }
    }
  }
}

.locations_table_panel {
  #store_results {
    table.doors_results th,
    table.doors_results td {
      text-align: right;
    }
    table {
      td.store_name.marker {
        display: table-cell;
      }
      td {
        span.store_name {
          display: block;
        }
      }
    }
  }
}

.section-esearch {
  #search-wrapper {
    .loading {
      top: 20px;
    }
    #col-1 {
      .results-header.accordion-header {
        background: transparent url('/media/export/images/endeca_search_v1_up_arrow.png') no-repeat scroll 0% 18px;
      }
      .results-header.accordion-header.collapsed {
        background: transparent url('/media/export/images/endeca_search_v1_down_arrow.png') no-repeat scroll 0% 18px;
      }
    }
    #col-1 .accordion-content {
      a {
        span.close {
          float: left;
          margin-left: 3px;
        }
      }
      .refinement-shade {
        a {
          span.close {
            float: none;
            margin-left: 0px;
            margin-right: 162px;
          }
        }
      }
    }
  }
}

.offer-promo-v1 {
  .content {
    .headline,
    .cta {
      white-space: normal;
    }
  }
}

.cs-qa-page-section-row-v1 {
  .answer-col {
    left: 0px !important;
    right: auto !important;
  }
}

.promo_product_row {
  .product.both {
    .button {
      white-space: normal;
    }
  }
}

.contact_us_wrap {
  #contact-form-wrap {
    .first-column-wrap {
      .form_element.country_container {
        margin-top: 30px;
      }
    }
    .second-column-wrap {
      .form_element.button_container {
        float: left;
        width: 100%;
      }
    }
  }
}

#top_wrapper {
  .outer-wrap {
    .three-step-sticky-header {
      .title {
        padding: 0 0 0 2%;
      }
    }
    .top-right.column {
      width: 35%;
    }
  }
}

.featured-product-carousel {
  .flex-direction-nav {
    .flex-next {
      right: 10px !important;
    }
    .flex-prev {
      left: 10px !important;
    }
  }
}

.featured-product-carousel-formatter-v1 {
  .quote-slide {
    padding: 28% 17% 0 14% !important;
  }
}

.mm_3col_1tout_v1 .explore {
  a.button.inverse {
    width: 200px;
    color: #5dba98 !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .hotlists-nav-header {
    .hotlists-nav {
      .hotlist-tab {
        height: 105px !important;
      }
    }
  }
  .section-careers {
    #node-26402 {
      min-width: 768px !important;
    }
    #node-26404 {
      min-width: 768px !important;
    }
    .basic-responsive-v1 {
      width: 100%;
      margin: 0px;
      padding: 40px;
    }
  }
}

.try-it-on-formatter-v1 {
  .outer-wrap {
    text-align: left;
  }
  .swatches {
    .swatch {
      float: left;
    }
  }
}

.lpPoweredBy {
  display: none;
}

.content {
  .header-nav-formatter-v1 {
    .shortcuts {
      .header-nav-row-v1 {
        .lpPoweredBy {
          display: none;
        }
        .lpEmtStarRating {
          display: none;
        }
        .lpStaticButtonTR {
          a {
            padding: 0 0;
          }
        }
      }
      li.header-nav-row-v1.live-chat a {
        border: none !important;
        background: none !important;
        margin: 0;
        padding: 0;
        width: auto;
        height: auto;
      }
      li.header-nav-row-v1.live-chat a img {
        border: none !important;
        background: none !important;
        margin-left: 5px;
        padding: 0;
        width: auto;
        height: 17px;
      }
    }
  }
}

fieldset.fs {
  div.form_element {
    float: right;
    margin: 5px 0px 10px 15px;
  }
  div.form_element.country_container,
  div.form_element.delivery_instructions_container,
  div.form_element.default_ship_container {
    width: 100%;
  }
  .phone_label_container {
    float: right;
    width: 100%;
  }
}

#main.multi {
  .address-container {
    margin: 0 0px 10px 40px;
  }
}

.pc_spp_view {
  .col2 {
    .form {
      input.form-text {
        height: 35px;
      }
    }
  }
}

.module-spp-detail {
  .col2 {
    .shades {
      .col2 {
        ul {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

.pc_spp_view {
  .spp-sticky-bar {
    .sticky_content {
      .dropdown {
        background-position: 10px -123px;
        padding-left: 33px;
        &.active {
          background-position: 10px 12px;
        }
        .selected_sku {
          background-position: -81px -278px;
        }
      }
    }
  }
}

.cl-mpp-product-layout-v1 {
  .mpp-grid {
    ul.view-small {
      li.mpp-box {
        .mpp-product {
          .badge {
            right: 0px;
          }
          .badge_109 {
            background: #fe6cb5;
          }
        }
        .mpp-product-hover-view {
          .badge {
            right: 20px;
          }
          .badge_109 {
            background: #fe6cb5;
          }
        }
        .online-price-hover-content {
          display: none;
          background: #fff;
          border: 1px solid #000;
          padding: 0.5%;
          position: absolute;
          width: 95%;
          height: 60px;
          margin-top: -60px;
          overflow: hidden;
        }
        .unit-price {
          color: #000000;
        }
      }
    }
  }
}

.module-spp-detail {
  .col2 {
    .product-size {
      font-size: 18px;
      margin-bottom: 5px;
    }
    .price.unit-price {
      border-top: 1px solid #dbdbdb;
      padding-top: 0px;
    }
    .price {
      color: gray;
    }
    .price.unit-price {
      color: #000000;
    }
    .online-price {
      cursor: pointer;
    }
    .online-price-hover-content {
      display: none;
      background: #fff;
      border: 1px solid #000;
      padding: 0.5%;
      position: absolute;
      width: 18%;
    }
  }
}

.cl-spp-slider-formatter-v1 {
  .handle {
    right: auto;
    margin-left: -23px;
    margin-right: 0;
  }
  .slider-large .changeable {
    float: left;
  }
  span.slider-formatter-img {
    left: 510px;
    right: auto !important;
  }
  span.slider-formatter-before-text {
    right: auto !important;
    left: 85px;
  }
}

.footer-top-v1 {
  .social-links {
    width: 18% !important;
  }
  .signup-forms {
    width: 64%;
  }
}

.shoppable-ad-v1 {
  .hotspots {
    left: 50%;
    right: auto;
  }
}

.mpp-product-v1 {
  .product-info {
    .product-price {
      line-height: 1.3;
    }
    .unit-price {
      color: #000000;
    }
    .online-price-hover-content {
      display: none;
      background: #fff;
      border: 1px solid #000;
      line-height: 1.3;
      margin-top: -62px;
      padding: 5px;
      position: absolute;
      width: 200px;
    }
  }
}

.multi-power-couples-row-v1 {
  .product-info {
    .unit-price {
      color: #000000;
    }
    .online-price-hover-content {
      display: none;
      background: #fff;
      border: 1px solid #000;
      line-height: 1.3;
      margin-top: -62px;
      padding: 5px;
      position: absolute;
      width: 200px;
    }
  }
}

.pcg-product-v1 {
  .product-tout {
    .product {
      .product-info {
        min-height: 155px;
        .unit-price {
          color: #000000;
        }
        .online-price-hover-content {
          display: none;
          background: #fff;
          border: 1px solid #000;
          margin-top: -81px;
          padding: 5px;
          position: absolute;
          width: 160px;
        }
      }
    }
  }
}

.pcg-product-large-v1 {
  .mpp-box {
    .product-info {
      .unit-price {
        font-family: 'spoiler', Arial, sans-serif;
        font-size: 15px;
        line-height: 17px;
        margin: 0;
        padding: 0 17.25%;
        color: #000000;
      }
      .online-price-hover-content {
        display: none;
        background: #fff;
        border: 1px solid #000;
        margin-top: -81px;
        padding: 2%;
        position: absolute;
        width: 67%;
      }
    }
  }
}

.three-step-row-v1 {
  .row-products {
    .product {
      .unit-price {
        color: #000000;
      }
      .online-price-hover-content {
        display: none;
        background: #fff;
        border: 1px solid #000;
        margin-top: -81px;
        padding: 2%;
        position: absolute;
        width: 67%;
      }
    }
  }
}

.power-couples-row-v1 {
  .product-info {
    .unit-price {
      color: #000000;
    }
    .online-price-hover-content {
      display: none;
      background: #fff;
      border: 1px solid #000;
      margin-top: -81px;
      padding: 2%;
      position: absolute;
      width: 67%;
    }
  }
}

.mascara-product-v1 {
  .product-info {
    .unit-price {
      color: #000000;
    }
    .online-price-hover-content {
      display: none;
      background: #fff;
      border: 1px solid #000;
      margin-top: -72px;
      padding: 2%;
      position: absolute;
      width: 67%;
    }
  }
}

.mascara-module-row-v1 {
  .product-info {
    .unit-price {
      color: #000000;
    }
    .online-price-hover-content {
      display: none;
      background: #fff;
      border: 1px solid #000;
      margin-top: -72px;
      padding: 2%;
      position: absolute;
    }
  }
}

#main.multi #works-well-with-wrapper td {
  text-align: right;
}

.featured-product-carousel-formatter-v1 {
  .product-info {
    .unit-price {
      color: #000000;
    }
    .online-price-hover-content {
      display: none;
      background: #fff;
      border: 1px solid #000;
      margin-top: -72px;
      padding: 0.5%;
      position: absolute;
      width: 14%;
    }
  }
}

.sa-product {
  .marker {
    float: left;
  }
}

#contact-form-wrap.personal-info-form-container .second-column-wrap .registered_member_container {
  width: 75% !important;
}

.product-content-carousel-v1 {
  .flexslider {
    .mpp-product-v1 {
      .product:hover,
      .product {
        .btn-quickview {
          display: none !important;
        }
      }
    }
  }
}

#ff-content {
  direction: rtl;
}

#node-35090 {
  div.cta {
    padding-right: 730px !important;
  }
}

div.cta {
  padding-right: 0px;
}

.my-account-sku {
  .unit-price {
    color: #000000;
  }
  .online-price-hover-content {
    display: none;
    background: #fff;
    color: #000000;
    border: 1px solid #000;
    padding: 5px;
    position: absolute;
    width: 180px;
    height: 60px;
    margin-top: -60px;
    margin-right: -25px;
    overflow: hidden;
  }
}

.node-elc-mpp {
  .field-elc-mpp-content {
    .cl-mpp-product-layout-v1 {
      .mpp-wrapper {
        h1 {
          span.mpp_cat_header {
            float: right !important;
          }
          span.item-count {
            float: left !important;
            margin: 10px 10px 0;
          }
        }
      }
    }
  }
}

.cta.left_btn {
  padding-right: 660px !important;
}

.signin_container.outer-wrap {
  overflow: auto !important;
  .signin_submit {
    display: block !important;
  }
}

#signin {
  .signin_container {
    form {
      .form-item.email_promotions {
        input[type='checkbox'] {
          float: right;
        }
      }
      .form-item.sms_promotions {
        label {
          width: 690px;
          margin-right: 7px;
        }
      }
    }
  }
}

.popup-offer-v1 {
  .popup-offer-close {
    background: transparent url('/media/export/cms/images/icon-close.png') no-repeat scroll 0 0/15px 15px;
    margin: 10px;
  }
  .outer-wrap {
    background: url('/media/export/cms/tout/welcome_pop_up_dt.jpg');
    border: 3px solid $color-black;
    background-size: cover;
    height: 350px;
    overflow: hidden;
    padding: 25px 30px 28px 120px;
    width: 685px;
    #{$ldirection}: unset;
    z-index: 10000;
    position: fixed;
    margin: 0;
    top: 50%;
    #{$rdirection}: 50%;
    transform: translate(-50%, -50%);
    .subheading {
      margin: 0;
      width: 70%;
    }
  }
  h2 {
    text-align: $ldirection;
    font-weight: bold;
  }
  .popup-offer-footer-msg {
    padding-top: 5px;
    width: 100%;
    .terms {
      float: $ldirection;
      padding-#{$rdirection}: 40%;
    }
  }
  .form-submit-wrap {
    input.button[type='submit'] {
      vertical-align: middle;
      margin-#{$ldirection}: 4px;
      font-size: 1.3em;
      line-height: 1.5;
      padding: 6px 10px;
    }
    .form-text {
      width: 53%;
    }
  }
}

.site-logo-responsive-v1 {
  .left_logo-link {
    float: right;
  }
  .right_logo-link {
    float: left;
  }
}

.multi-power-couples-main-v1 {
  .col2 {
    .promo_product_row {
      .both {
        left: 100px;
      }
    }
  }
}

.product {
  .online-price-hover-content {
    display: none !important;
  }
}

ul#mpp-product-grid {
  .product-info {
    p.price.unit-price {
      display: block;
    }
  }
}

@media screen and (min-width: 0\0) {
  .i18n-he-e-IL {
    .main-menu {
      .title {
        font-size: 12.5px;
      }
    }
  }
}

.appt-book {
  #booking-step3 {
    .book-appt-container {
      .registration__email-list,
      .registration__terms,
      .registration__sms-list {
        float: right;
        input {
          margin-left: 8px;
          float: right;
        }
      }
      .registration__sms-list {
        display: block;
      }
      .registration__terms.error {
        color: #ef6ea8;
        a {
          color: #ef6ea8;
        }
      }
    }
    #appt-book-confirm-error-messages {
      .error-message {
        font-weight: bold;
      }
    }
  }
  .service-select {
    .service {
      .service-content {
        padding: 0 10%;
      }
      .service-image {
        padding: 0;
      }
      .service-details {
        min-height: 135px;
        margin: 0;
      }
      .selection-bar {
        padding: 10px 0;
      }
      &.selected {
        .service-content {
          padding: 0 10%;
        }
      }
    }
  }
  .confirmation-container {
    .appt-date-cal {
      background-position: 5em 0.1em !important;
      .add-cal-title,
      .add-cal-desc {
        display: none;
      }
    }
  }
  .services-skincare,
  .services-makeup {
    .section-header {
      font-size: 36px;
    }
  }
  .appointment-select {
    .appointments-container {
      .booking-step {
        .confirm-container {
          .total-time {
            border-right: 1px solid $color-gray;
            padding: 15px 0 0 20px;
            text-align: center;
          }
        }
        text-align: right !important;
        h3,
        h4 {
          text-align: right;
        }
        .services {
          float: right;
          .service {
            float: right;
          }
        }
        .selects-container {
          .select-container {
            float: right;
            text-align: right;
            padding: 2.5% 0 2.5% 0.7%;
          }
        }
      }
    }
  }
  .appointment-container {
    .your-lessons,
    .appt-date {
      p {
        display: inline-block;
      }
    }
  }
}

.appt-book-overlay.appt-book-services-overlay {
  .overlay-content-container {
    height: auto !important;
  }
}

.appt-book-overlay.appt-book-book-conflict-overlay {
  .overlay-content-container {
    height: auto;
    top: 50%;
    left: 50%;
    margin-top: -95px;
    margin-left: -120px;
    .overlay-content {
      margin: 10px;
    }
    .overlay-close {
      left: 10px;
      width: auto;
    }
  }
}

.appt-book-overlay.my-appointments-overlay {
  .overlay-close {
    left: 10px;
  }
  .overlay-content {
    text-align: right !important;
    span {
      text-align: right !important;
      p {
        display: inline-block;
        margin: 0 !important;
      }
    }
    .button {
      float: right !important;
      margin: 0 0 0 36px;
    }
  }
}

.appt-book-datepicker-overlay {
  .overlay-content-container {
    top: 115px !important;
    .overlay-close {
      right: 6px;
    }
    .pika-lendar {
      .pika-button.pika-day {
        text-align: center;
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-height: 1024px) and (orientation: portrait) {
  .site-logo-responsive-v1 a {
    padding: 0 5px;
  }
  .appt-book {
    .service {
      .service-details {
        min-height: 145px !important;
      }
    }
  }
}

.gm-style {
  .gm-style-iw {
    right: 0px !important;
  }
}

.device-pc .wrap.page-spp.how_to_use.spp-extra {
  padding-bottom: 20px;
  border-bottom: solid 1px #ccc;
}

.ingredients_container .ingredients .ingredients__header {
  font-weight: bold;
}

body {
  &.section-foundation-finder {
    #foundation-finder-quiz {
      .foundation-finder {
        .ff-quiz {
          .slick-prev {
            left: 0;
          }
          &__slide--1 {
            .ff-quiz {
              &__slide-container {
                text-align: right;
                background-position: -320px;
              }
            }
          }
        }
      }
    }
  }
}

.generic-rows {
  .hero-full-width {
    .content-block {
      &__links {
        .hero-full-width__link {
          .button--dark {
            padding: 0 20px;
            text-transform: none;
          }
        }
      }
    }
  }
}
